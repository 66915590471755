
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
  },
  props: {
    // userId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup() {
    const router = useRouter();
    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };
    const goEvent = () => {
      router.push({
        name: "Event",
      });
    };
    return { onClose, goEvent };
  },
});
